export default {
  setState(state, data) {
    setState(state, data)
  },
  addPane(state, { view, name, query }) {
    let index = state.panes.findIndex((item) => item.view === view)
    if (index === -1) {
      let panes = state.panes
      panes.push({ view, name, query })
      setState(state, { panes })
    } else {
      let panes = state.panes
      panes[index].name = name
      panes[index].query = query
      setState(state, { panes })
    }
  },
  removePane(state, { activeKey, targetKey, callback }) {
    let lastIndex
    state.panes.forEach((pane, i) => {
      if (pane.view === targetKey) {
        lastIndex = i - 1
      }
    })
    const panes = state.panes.filter((pane) => pane.view !== targetKey)
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].view
      } else {
        activeKey = panes[0].view
      }
    }
    state.panes = panes
    localStorage.setItem('panes', JSON.stringify(panes))
    callback && callback(activeKey)
  },
}

const setState = (state, data) => {
  for (let item in data) {
    state[item] = data[item]
    localStorage.setItem(
      item,
      typeof data[item] === 'object' && data[item] !== null
        ? JSON.stringify(data[item])
        : data[item]
    )
  }
}

//采购
const outAndInStorage = () => import('./view/inventoryManage/outAndInStorage.vue')
const inventoryQuery = () =>
  import('./view/inventoryManage/goodsInventorySearch/inventoryQuery.vue')
const groupGoodsSearch = () =>
  import('./view/inventoryManage/goodsInventorySearch/groupGoodsSearch.vue')
const inventoryVerification = () =>
  import('./view/inventoryManage/invertoryVerification/inventoryVerification.vue')
const addVerificationList = () =>
  import('./view/inventoryManage/invertoryVerification/addVerificationList.vue')
const inventoryFlow = () => import('./view/inventoryManage/inventoryFlow')
export default [
  {
    path: '/',
    name: 'index',
    redirect: '/inventoryQuery',
    component: inventoryQuery,
  },
  {
    path: '/inventorySettings',
    name: 'inventorySettings',
    component: outAndInStorage, // 库存设置
  },
  {
    path: '/inventoryQuery',
    name: 'inventoryQuery',
    component: inventoryQuery, //  库存查询
  },
  {
    path: '/groupGoodsSearch',
    name: 'groupGoodsSearch',
    component: groupGoodsSearch, //  库存查询
  },
  {
    path: '/inventoryVerification',
    name: 'inventoryVerification',
    component: inventoryVerification, //  库存盘点
  },
  {
    path: '/addVerificateResult',
    name: 'addVerificateResult',
    component: addVerificationList, //录入结果
  },
  {
    path: '/addVerificationList',
    name: 'addVerificationList',
    component: addVerificationList, //添加库存盘点单
  },
  {
    path: '/inventoryFlow',
    name: 'inventoryFlow',
    component: inventoryFlow, //库存流水
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('./view/common/404'),
  },
]

import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { message } from "ant-design-vue"


message.config({
  duration: 3,// 持续时间
  maxCount: 1, // 最大显示数, 超过限制时，最早的消息会被自动关闭
  top: 100,// 到页面顶部距离
});

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

import './common/constants'
import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import './public-path'
import App from './App.vue' // 根组件
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import myTips from './common/myTips'
import './utils/antd'
import './css/common.scss'
import breadcrumb from '@component/breadcrumb/index'
import actions from './qiankun/actions'
import i18n from './utils/lang'

Vue.component('breadcrumb', breadcrumb)

const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

Vue.prototype.priv = function () {
  return true
  // return priv.apply(this, arguments);
}

Vue.prototype.global = {}

Vue.prototype.global.currentSession = null
Vue.prototype.global.currentModule = null

Vue.prototype.showShop = true

Vue.prototype.showTips = function (event) {
  //增加全局文案提示框
  layer.tips(myTips[event.target.id], '#' + event.target.id, {
    tips: [1, '#3697DE'],
    time: 0,
  })
  if (!event.target.hasMouseoutEvent) {
    //hasMouseoutFun用于标记是否绑定了mouseout事件
    event.target.hasMouseoutEvent = true //如果没有则绑定mouseout事件
    $(event.target).bind('mouseout', function () {
      layer.closeAll('tips')
    })
  }
}

Vue.prototype.$bus = new Vue()

Vue.use(VueQuillEditor)
Vue.prototype.layer = {
  empty: () => {
    //搜索为空
    layer.msg(`<span style='color:red'>${$t('Empty')}~</span>`, {
      icon: 5,
    })
  },
  success: () => {
    //操作成功
    layer.msg(`${$t('Success')}~`)
  },
  selectAnOrder: () => {
    //选择一个订单
    layer.msg(`${$t('SelectAnOrder')}~`)
  },
}

let router = null
let instance = null
function render(props = {}) {
  console.log(props)
  const { container } = props
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
    Vue.prototype.$parentStore = props.store
  }
  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN_PARENT__
      ? `/${window.__QIANKUN_PARENTNAME__}/${props.name}`
      : window.__POWERED_BY_QIANKUN__
      ? `/${props.name}`
      : '/',
    mode: 'history',
    routes,
  })
  Vue.use(VueRouter)
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue2] vue app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}

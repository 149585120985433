export default {
//采购单新建页
	cgdxjy1:"供应商供货的额外折扣，影响商品成本，比例在0~1之间，1代表无折扣，支持6位小数点",       
	cgdxjy2:"比例设定在0~1之间,支持6位小数点，可参考供应商默认折扣比例；注意，若不使用折扣。折扣比例填写为1！", 
	cgdxjy3:"采购金额=下单数量*下单单价*折扣比例", 
	cgdxjy4:"应付金额=∑采购金额-返利", 
	cgdxjy5:"品牌方给的货补，相当于公司的收入，用于抵扣部分货款，不影响商品成本，支持两位小数", 
	cgdxjy6:"代表此批采购的备货对象类型",
	cgdxjy7:"使用折扣总额=∑[下单数量*下单单价*（1-折扣比例）]",
	cgdxjy8:"设置全部商品的折扣比例为默认折扣比例",
	cgdxjy9:"供应商备货的采购进价，在供价管理页面维护",
	cgdxjy10:"采购总额=∑采购金额",
	cgdxjy11:"预付款是我司预先支付给品牌方的钱款，用于采购下单时抵用货款；前框勾选代表此笔采购单预占预付款金额，括号里为预付款的可用余额；前框无勾选代表供应商未开启预付款预占属性，请在“供应商预付款”页面设置",
	cgdxjy12:"采购单预计到货时间：无论创建还是修改，当天不可预约当天入库，且当天18:00后不可预约第二天的入库。预计入库时间变动请及时修改预约，避免仓库仓容已满拒收！",
	cgdxjy13:"单价以特殊供价作为参考值，浮动范围在特殊供价的上下20%内为宜；特殊供价在“采购->供价管理”页面设置，若没有设置请积极维护！",
	cgdxjy14:"正常单为除赊销单外的其余采购单！",
	cgdxjy15:"当前预付款真实余额",
//采购单列表页
	cgdlby1:'此批采购单的备货对象类型',//渠道类型
	cgdlby2:'此批采购单的备货对象类型',//渠道类型
	cgdlby3:'在列表页快捷查询指定单的采购明细',//指定单品
//采购单详情页
	cgdxyy1:"下单应付=∑采购金额-返利",       
  cgdxyy2:"最后应付=∑实际入库金额-返利", 
	cgdxyy3:"采购金额=下单数量*下单单价*折扣比例", 
	cgdxyy4:"入库单价=单价*折扣比例", 
	cgdxyy5:"单价可多次修改，直接影响采购金额和入库单价；单价修改范围为原单价的上下5%(含5%)", 
	cgdxyy6:"实际入库金额=入库单价*实际入库数", 
	cgdxyy7:"供应商供货的额外折扣，影响商品成本，比例在0~1之间，1代表无折扣，支持6位小数点",  //设定默认折扣比例
	cgdxyy8:"比例设定在0~1之间,支持6位小数点，可参考供应商默认折扣比例；注意，若不使用折扣。折扣比例填写为1！",  //使用折扣
	cgdxyy9:"已维护的发票商品数量",   //已到票数
	cgdxyy10:"未维护的发票商品数量",  //未到票数
	cgdxyy11:"折后单价=单价*折扣比例",
	cgdxyy12:"已结算金额：已扣除返利的实付金额",
	cgdxyy14:"预付款是我司预先支付给品牌方的钱款，用于采购下单时抵用货款；前框勾选代表此笔采购单预占预付款金额，括号里为预付款的可用余额；前框无勾选代表供应商未开启预付款预占属性，请在“供应商预付款”页面设置",
//退货单
	thdlby1:'快捷查询指定单品的退货明细',//渠道类型
	thdlby2:'勾选是否显示订单备注信息',
//采购发票
	cgfplby1:"维护的发票含税/去税金额与采购含税/去税总计存在差异，需要无差异时才能继续流程", 
	cgfpxqy1:"发票去税总额=发票含税总额/税率，保留两位小数", 
	cgfpxqy2:"发票含税总额=采购含税总额-返利抵扣总额", 
//调拨单
	dbdlby1:"快捷查询指定单品的采购明细", 	
	dbdxj1:'仓内调拨即实际物理仓一致，是不同逻辑仓的库存转移，不需要填物流方式；异仓调拨即实际物理仓不一致，物流方式必填',
	dbdxj2:'调拨单预计到货时间：无论创建还是修改，当天不可预约当天入库，且当天18:00后不可预约第二天的入库。预计入库时间变动请及时修改预约，避免仓库仓容已满拒收！',
//特殊供价
	tsgjlby1:'供应商备货的采购进价',//渠道类型	
//库存锁定
	kcsd1:'锁定单的截止时间可多次修改，设定的时间必须在当前时间之后，一旦满足激活时间，锁定单作废，释放商品库存',
	kcsd2:'锁定标签为全公司统筹活动时添加，为锁定原因的统一标记',
	kcsd3:'系统库存是未扣减申请数量的当前真实库存',
	kcsd4:'可用库存是未扣减申请数量的当前可用库存数',
//预付款
	gysyfk1:'预付款余额是预支给品牌商的真实余额；预付款可用是在余额的基础上扣减了预付款预占的可用余额',
	gysyfk2:'采购单下单时抢占的预付款，是虚拟占用，财务结算时才真正扣减',
	gysyfk3:'品牌方给的货补，相当于公司的收入，在订单维度下可用于抵用部分货款，不影响商品成本',
	gysyfk4:'当前预付款真实金额，发票结算后即时扣减；预付款结余=∑充值金额-∑发票结算金额',
	gysyfk5:'预付款入库可用=预付款结余-采购已入库金额；预付款在途可用=预付款结余-采购已入库-采购在途',
	gysyfk6:'采购入库=∑入库应付-∑发票已结算金额',
	gysyfk7:'采购在途=∑未入库采购单的下单应付',
	gysyfk8:'采购单已扣除返利的最后应付金额；当入库应付=已结算金额，预占返利=已结算返利时，此笔采购单结算完毕',
	gysyfk9:'采购发票已结算的部分金额',
	gysyfk10:'当采购单使用返利抵扣应付金额，发票结算的返利',
	gysyfk11:'赊销',
	gysyfk12:'产生这条记录的时间',
	gysyfk13:'财务真实充值金额的交易日期',
	gysyfk14:'发票结算时的直接付款部分',
	gysyfk15:'点击修改',
//库存同步
	kctb1:'在同步库存数量的基础上预留的库存数',
	kctb2:'同步库存数小于/等于预警库存数会邮件提醒',
	kctb3:'宝贝同步库存数=仓库按比例同步数',
	kctb4:'同步状态与宝贝是否上架无关，同步成功仅代表库存数量成功同步到前台',
//库存成本
	cbtzd1:'是调整后的成本，支持6位小数；调整后当有一笔新采购单入库时，成本价=((新调整成本* 结余数量) + （采购单价*采购数量)) / （结余数量+采购数量）',
	cbtzd2:'调整差额=（调整成本-实时成本）*库存数量',
	cbtzd3:'只有已确认状态下，成本调整价格才生效',
	cbtzd4:'是调整后的成本，支持6位小数；调整后当有一笔新采购单入库时，成本价=((新调整成本* 结余数量) + （采购单价*采购数量)) / （结余数量+采购数量）',
	cbtzd5:'调整差额=（调整成本-实时成本）*库存数量',
	cbtzd6:'审核通过即调整价格生效；驳回和作废都相当于作废，单据不可恢复只能重建',
//库存成本
	kccb1:'OMS使用移动平均成本算法，系统库存均无法追溯来源，库存成本为当前所有仓库、所有库存状态的平均成本； 实时成本=（入库单价*采购入库数  + 上次结余金额） /  （采购入库数 + 上次结余库存）',
//组合商品列表页
	tclby1:'指明组合商品使用状态；组合商品一旦创建不可删除，只能通过ON/OFF按钮更改使用状态',
	tclby2:'表明供应链是否备货，审核通过的组合商品即等待供应链备货；备货状态与组合商品状态无关',
	tclby3:'ON/OFF按钮更改组合商品状态；调价按钮只能修改组合商品价格与类型；编辑按钮可以修改所有组合商品明细',
	tclby4:'类型为商品时，固定售价默认为商品零售价，可修改；类型为赠品时，固定售价默认为0，不可修改',
	tclby5:'固定售价取决于SKU类型，类型为赠品时，固定售价默认为0；类型为商品时，仅支持两位小数',
	tclby6:'标识该组合商品是否设置了预打包任务',
  	tclby7:'已转化的OMS销售单是否允许人工拆分，与划分/wms大件拆分无关',
 //采购发票管理列表页
	cgfpgllby1:'未合并时为单发票的开票时间，合并后为所有发票中最早开票时间',
	cgfpgllby2:'未合并时为单发票的创建时间，合并后为所有发票中最早创建时间',
	cgfpgllby3:'未合并时为单发票的状态，合并后为所有发票的状态',
//销售发票
  	xsfpxqy1:'请上传三证合一营业执照加红章和一般纳税人资格证加红章！若没有一般纳税人资格证纸质版，可在税局网站查询好结果 之后盖红章！(点击右边图标上传图片)',    
//原始单对账列表页
	ysddzlby1:'淘系平台，订单所有商品处于发货状态则为“是”；如果为“否”，说明客户提前确认收货',
	ysddzlby2:'实际发货的商品总额',
	ysddzlby3:'发货后未确认收货前已结算的退货金额，售后类型为退货退款',
	ysddzlby4:'发货后未确认收货前已结算的退款金额，售后类型为退货退款/仅退款/换货中的不含退货金额的部分',
	ysddzlby5:'未对账即系统未开始对账；系统对账成功即支付宝收入总计与待对账总计一致；系统对账失败则进入人工对账流程；人工对账成功即在系统对账失败的前提下，实际收款金额与支付宝流水总计一致',
	ysddzlby6:'OMS应收=发货金额+邮费-售中退货',
	ysddzlby7:'订单实际收款金额，当OMS应收-售中退款！=支付宝收入总计，则对账失败，进入人工手动对账流程',
	ysddzlby8:'如果订单是多包裹发货，以第一单发货时间为准',
	ysddzlby9:'支付宝流水的产生时间',
	ysddzlby10:'货款金额，应收金额=发货金额+邮费-售中退货',
	ysddzlby11:'待对账金额=发货金额+邮费-售中退款-售中退货',
	ysddzlby12:'收款金额以支付宝收入总计为准，∑收款金额=待对账总计=支付宝收入总计时，系统对账成功，否则对账失败',
	ysddzlby13:'天猫平台直销订单的支付宝流水对账明细，只计入支付宝收款金额',
	ysddzlby14:'批量强制人工对账，使得∑收款金额=支付宝收入总计，具体明细需财务人工查看',
//商品详情页
	spxqy1:'耗材/包材 与 普通商品/虚拟商品  在各自有库存时不能相互修改，但耗材和包材之间、普通商品与虚拟商品之间可以相互修改；普通商品改为虚拟商品时注意，虚拟商品是可以自动出库的',
	spxqy2:'商品严格遵循一品一码，当存在一品多码，请直接英文逗号维护多个；当存在一码多品，则需要维护虚拟条码，WMS也需要贴码入库',
	spxqy3:'A类最重要，其余重要性依次递减!',
//批量审单任务
	plsdrw1:'批量转快递时,原始仓库必选且只能选一个！！！',	
//销售财审配置
	cspz1: '如果框内没有值，默认不校验该部分',
	//平台-系统商品对应
	pxspdy1: '1.生成erp商品需确保平台商家编码均有值；<br/>2.在平台多宝贝对应erp系统一个宝贝的情况下，批量生成和更新erp商品时以平台最新更新时间的宝贝信息为准；',
	pxspdy2: '1.修改平台商家编码，系统会根据新的平台商家编码匹配erp商品；<br/>2.新的平台商家编码会同步更新到平台店铺后台;'
}
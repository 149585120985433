<template>
  <div class="App">
    <a-locale-provider :locale="locale">
      <a-spin :spinning="$store.state.loading">
        <keep-alive exclude="addVerificationList">
          <router-view />
        </keep-alive>
      </a-spin>
    </a-locale-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import en_US from 'ant-design-vue/lib/locale-provider/en_US'
import th_TH from 'ant-design-vue/lib/locale-provider/th_TH'
import actions from './qiankun/actions'
import { ConfigProvider } from 'ant-design-vue'
export default {
  name: 'App',
  components: {
    'a-locale-provider': ConfigProvider,
  },
  data() {
    return {
      locale: this.$store.state.locale || zh_CN,
    }
  },
  created() {
    // onGlobalStateChange 第二个参数为 true，表示立即执行一次观察者函数
    actions.onGlobalStateChange(({ lang }) => {
      this.changeLang(lang)
    }, true)
  },
  methods: {
    changeLang(lang) {
      localStorage.setItem('language', lang) // 切换多语言
      this.$store.state.lang = lang
      this.$i18n.locale = lang // 设置给本地的i18n插件
      console.log('lang:', lang)
      switch (lang) {
        case 'zh':
          this.locale = zh_CN
          break
        case 'en':
          this.locale = en_US
          break
        case 'th':
          this.locale = th_TH
          break
        default:
          this.locale = zh_CN
          break
      }
      console.log('locale:', this.locale)
    },
  },
}
</script>

<style scoped>
.App {
  /* padding: 10px 0 0 20px; */
  background: #f0f3f4;
}
body {
  background-color: #f0f3f4;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>

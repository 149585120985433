import moment from 'moment'
export default {
  loading: false,
  lang: localStorage.getItem('lang') || 'zh',
  locale: getData('locale', {}),
  userSession: getData('userSession', {}),
  currentShop: getData('currentShop', {}),
  exclude: null,
  panes: getData('panes', [{ view: 'saleOrders' }]),
  pagination_setting: {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
  show_time_setting: {
    hideDisabledOptions: true,
    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
  },
  rate: localStorage.getItem('zoomRate'),
}

function getData(name, defaultData) {
  let data = localStorage.getItem(name)
  if (data === undefined || data === 'undefined' || data === null || data === 'null') {
    return defaultData
  }
  try {
    return JSON.parse(data)
  } catch (err) {
    console.log(err)
  }
  return data
}

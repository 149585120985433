var constants = {
  commonWarehoustType: {},
  shopPlatforms: {},
  commonPlatforms: {
    TMALL: "天猫",
    TMALL_I18N: "天猫国际",
    TAOBAO: "淘宝集市"
  },
  distributorPlatforms: {},
  guideSteps: {},
  currecies: {
    RMB: "人民币",
    US: "美元"
  },
  directChannelTypes: {
    TMALL: "天猫店",
    TAOBAO: "淘宝店",
    POP: "POP",
    BRAND_OFFICIAL: "品牌官网",
    CUN_TAO: "村淘",
    GROUP_BUY: "团购"
  },
  fenxiaoChannelTypes: {},
  shopRegions: {
    DOMESTIC: "国内",
    OVERSEAS: "跨境"
  },
  shopCompanies: {
    BELRED: "belred"
  },
  paymentTypes: {
    银行转账: "银行转账",
    支付宝: "支付宝",
    微信支付: "微信支付"
  },
  shopCooprationModes: {
    DEALER: "经销",
    AGENT: "代销",
    SERVER: "服务",
    SELF: "自营"
  },
  distributorCooprationModes: {
    DISTRIBUTION: "经销",
    PROXY: "代销",
    OWE_SALE: "赊销"
  },
  shopTypes: {
    DIRECT: "直销",
    DISTRIBUTE: "分销"
  }
};

for (var p in constants.commonPlatforms) {
  constants.shopPlatforms[p] = constants.commonPlatforms[p];
  constants.distributorPlatforms[p] = constants.commonPlatforms[p];
}

global.constants = constants;
export default constants;
